<template>
  <div id="wrapper">
    <!-- Only render SideBar, Topbar, and Footer if the user is authenticated and layout is not hidden -->
    <SideBar v-if="isAuthenticated && !hideLayout" />

    <div id="content-wrapper" class="d-flex flex-column">
      <div id="content">
        <Topbar v-if="isAuthenticated && !hideLayout" />

        <div class="container-fluid px-5 mt-5">
          <router-view />
        </div>
      </div>

      <Footer v-if="isAuthenticated && !hideLayout" />
    </div>
  </div>
</template>

<script>
import Topbar from "@/components/common/Topbar.vue";
import Footer from "@/components/common/Footer.vue";
import SideBar from "@/components/common/Navbar.vue";
import { mapState } from "vuex";
export default {
  name: "App",
  components: {
    Topbar,
    Footer,
    SideBar,
  },

  computed: {
    hideLayout() {
      return this.$route.meta.hideLayout;
    },
    ...mapState(["isAuthenticated"]),
  },
};
</script>
