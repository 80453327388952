import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

window.addEventListener("beforeunload", (event) => {
  if (event.clientX < 0 && event.clientY < 0) {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  }
});

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/components/Login.vue"),
    meta: { hideLayout: true, requiresAuth: false },
  },
  {
    path: "/",
    name: "Login",
    component: () => import("@/components/Login.vue"),
    meta: { hideLayout: true, requiresAuth: false },
  },
  {
    path: "/forgetpassword",
    name: "ForgetPassword",
    component: () => import("@/components/resetPassword.vue"),
    meta: { hideLayout: true, requiresAuth: false },
  },

  {
    path: "/my-payments",
    name: "MyPayemnt",
    component: () => import("@/components/MyPayement.vue"),
    meta: { requiresAuth: true, hideLayout: false },
  },
  {
    path: "/all-payments",
    name: "AllPayments",
    component: () => import("@/components/AllPayment.vue"),
    meta: { requiresAuth: true, hideLayout: false },
  },
  // {
  //   path: "/dashboard",
  //   name: "home",
  //   component: HomeView,
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem("token");

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      return next("/login");
    }
  } else if (
    isAuthenticated &&
    (to.path === "/login" || to.path === "/forgetpassword")
  ) {
    return next("/my-payments");
  }

  next();
});

export default router;
