import gql from "graphql-tag";

//Logout
export const LOGOUT_MUTATION = gql`
  mutation LogoutUser {
    logoutUser {
      userId
      token
      tokenExpiration
      username
      role
    }
  }
`;

export const GET_PAYMENTS_FILES = gql`
  query GetPaymentFiles(
    $offset: Int!
    $limit: Int!
    $filter: PaymentFileFilter
  ) {
    getPaymentFiles(offset: $offset, limit: $limit, filter: $filter) {
      count
      data {
        id
        file_name
        processed_file_name
        file_status
        comment
        employee {
          first_name
          last_name
        }
        paid_date
        picked_date
        failed_status
        approved
        machine_id
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_CLIENTS = gql`
  query GetMasterFileClients {
    getMasterFileClients {
      id
      organization
    }
  }
`;
//UserRoles
//Get UserRoles
export const USER_USERGROUP_QUERY = gql`
  query UserUserGroups {
    userUserGroups {
      id
      user_id {
        email
        first_name
        last_name
      }
      group_id {
        group_id
        group_name
      }
    }
  }
`;

//user groyup

export const USERGROUP_QUERY = gql`
  query UserGroups($limit: Int, $offset: Int) {
    userGroups(limit: $limit, offset: $offset) {
      group_id
      group_name
    }
  }
`;

//Employee
export const ALL_EMPLOYEES_QUERY = gql`
  query Employees($limit: Int, $offset: Int) {
    employees(limit: $limit, offset: $offset) {
      first_name
      last_name
      id
    }
  }
`;

//User
export const ALL_USER_QUERY = gql`
  query Employees {
    employees {
      first_name
      last_name
      id
      initials
    }
  }
`;

//DAILY SCANS
export const ALL_USERS_QUERY = gql`
  query DailyScans($limit: Int!, $offset: Int!) {
    dailyScans(limit: $limit, offset: $offset) {
      scan_id
      client_id {
        organization
        phone
        org_email
      }
      scanner
      agency_invoice_status
      scan_doc_file
      scan_doc_name
      no_invoices
      scan_status
      processor
      validator
      picked_date
      processed_date
      srt_doc_file
      srt_status
      srt_lp
      srt_amount
      user_id
      pay_by
      pay_date
      paid_srt_doc_file
      paid_srt_date
      scan_type
    }
  }
`;

export const LOGIN_USER_MUTATION = gql`
  mutation LoginEmployee($username: String!, $password: String!) {
    loginEmployee(username: $username, password: $password) {
      authData {
        token
      }
      employee {
        id
        first_name
        last_name
        phone_number
        title
        address
        initials
      }
      user {
        role {
          id
          group_id {
            group_id
            group_name
          }
        }
      }
    }
  }
`;
export const DELETE_USER_MUTATION = gql`
  mutation DeleteFileCategory($deleteFileCategoryId: Int!) {
    deleteFileCategory(id: $deleteFileCategoryId)
  }
`;

export const ALL_CLIENTS_QUERY = gql`
  query Clients($filter: clientFilter, $offset: Int, $limit: Int) {
    clients(filter: $filter, offset: $offset, limit: $limit) {
      id
      address
      mail_address
      postal_code
      phone
      org_email
      sec_email
      organization
      po_box
      client_status
    }
  }
`;

export const REQUEST_RESETPASSWORD_MUTATION = gql`
  mutation RequestResetPassword($email: String!) {
    requestResetPassword(email: $email) {
      userId
      username
      email
    }
  }
`;

export const RESETPASSWORD_MUTATION = gql`
  mutation ResetPassword(
    $code: String!
    $userId: String!
    $confirmPassword: String!
    $password: String!
  ) {
    resetPassword(
      code: $code
      userId: $userId
      confirmPassword: $confirmPassword
      password: $password
    ) {
      email
      userId
      username
    }
  }
`;
