import { createStore } from "vuex";

export default createStore({
  state: {
    isAuthenticated: !!localStorage.getItem("token"),
  },
  getters: {
    isAuthenticated: (state) => state.isAuthenticated,
  },
  mutations: {
    login(state) {
      state.isAuthenticated = true;
    },
    logout(state) {
      state.isAuthenticated = false;
      localStorage.removeItem("token");
    },
  },
  actions: {
    login({ commit }, token) {
      localStorage.setItem("token", token);

      commit("login");
    },
    logout({ commit }) {
      commit("logout");
    },
  },
});
